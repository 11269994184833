import React, { Component } from 'react';
import { Container, Header, Grid, Label } from 'semantic-ui-react';

export default class Commitment extends Component {
    render() {
        return (
            <Container className='commitment section' textAlign="center" id="commitment">
                <Header as='h2' className='header'>Nuestro Compromiso</Header>
                <p className="section-description">Somos una clínica enfocada en la salud oral de nuestros pacientes, poniéndonos en tu lugar para que tengas un trato cálido, respetuoso, profesional y empático, dándote las mejores soluciones a los problemas de salud y estética dental.
Tenemos un personal calificado y certificado para que tengas la confianza absoluta a un precio razonable, nosotros nos encargamos de tu sonrisa, tu solo encárgate de usarla.</p>
                <Grid stackable centered className="commitments">
                    <Grid.Row columns={4}>
                        <Grid.Column className="commitment" textAlign="center">
                            <Label circular className="professional"></Label>
                            <h3>Trato Amistoso y Profesional</h3>
                        </Grid.Column>
                        <Grid.Column className="commitment" textAlign="center">
                            <Label circular className="technology"></Label>
                            <h3>Equipo de Alta Tecnología</h3>
                        </Grid.Column>
                        <Grid.Column className="commitment" textAlign="center">
                            <Label circular className="no-pain"></Label>
                            <h3>Tratamientos Sin Dolor</h3>
                        </Grid.Column>
                        <Grid.Column className="commitment" textAlign="center">
                            <Label circular className="costs"></Label>
                            <h3>Costos Competitivos</h3>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}
