import React from 'react';
import '../sass/main.scss';
import { Segment } from 'semantic-ui-react';
import Footer from '../components/Footer';
import BottomFooter from '../components/BottomFooter';
import Header from '../components/Header';

class Main extends React.Component {
    state = { is_dimmed: false };
    IsDimmedHandler = () => {
        this.setState({ is_dimmed: !this.state.is_dimmed });
    }
    render() {
        return (
          <Segment className="app-container">
            <Header dimmedHandler={this.IsDimmedHandler} isDimmed={this.state.is_dimmed}/>
            {this.props.children}
            <Footer />
            <BottomFooter />
          </Segment>
        )
    }
}

export default Main
