import React from 'react';
import { Container, Image } from 'semantic-ui-react';

export default function TeamMember(props){
    return (
        <Container className="team-member">
          <Image src={props.image} circular centered alt={props.name}/>
          <h3 className="name">{props.name}</h3>
          <p className="position">{props.position}</p>
        </Container>
    )
}
