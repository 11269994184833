import React, { Component } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import LaravelEncrypter from '../LaravelEncrypter';
import { Container, Header, Form, Input, Label } from 'semantic-ui-react';
import ThankyouModal from './ThankyouModal';
import ReactGA from 'react-ga';
import ToastContainer from './ToastContainer';
import ReactPixel from 'react-facebook-pixel';

export default class ContactForm extends Component {
    constructor(){
        super();
        //Initialize Encrypter
        let encrypter = new LaravelEncrypter();
        encrypter.generateKey(44);
        this.state = {
            email: '',
            fullname: '',
            isLoading: false,
            is_modal_open: false,
            key: encrypter.getKey(),
            emailValidation: false,
            fullnameValidation: false,
            is_form_clicked: false,
            message: encrypter.encrypt(Math.round(new Date().getTime()/1000).toString())
        };
        this.myNameInput = React.createRef();
        this.validFrom = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true, emailValidation: false, fullnameValidation: false });
        let body = {
            email: this.state.email,
            fullname: this.state.fullname
        };
        //Attach Other Values
        body[this.validFrom.current.name]= this.validFrom.current.value;
        body[this.myNameInput.current.name]= this.myNameInput.current.value;
        //Send the Request
        axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/subscriptions`, body).then(
            (response) => {
                this.setState({ is_modal_open: true, isLoading: false, fullname:'', email:'' });
                ReactGA.modalview('/newsletter/thank-you');
                ReactGA.event({ category: 'Contact', action: 'Subscribed'});
                ReactPixel.track('Contact', {action: 'Subscribed'});
            },
            (error) => {
                this.setState({ isLoading: false });
                if(error.response && error.response.status === 400) {
                    Object.keys(error.response.data).forEach(key => {
                        if(error.response.data[key].length > 0)
                            this.setState({[`${key}Validation`]: error.response.data[key][0]});
                    })
                } else {
                    toast.error(<ToastContainer icon='mail' message='An Error ocurred, please try again later.' />);
                }
                console.error(error.response);
            }
        );
    }
    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
    handleCloseModal = () => this.setState({ is_modal_open: false });
    handleFormClick = () => {
        if(!this.state.is_form_clicked){
            this.setState({ is_form_clicked: true });
            ReactGA.event({ category: 'Contact', action: 'Form Clicked'});
            ReactPixel.track('Contact', {action: 'Form Clicked'});
        }
    }
    render() {
        const fieldName = 'my_name';
        return (
            <Container textAlign='left' className='form-container'>
                <Header as='h2' inverted>Newsletter</Header>
                <p>Stay updated!</p>
                <Form onSubmit={this.handleSubmit} onClick={this.handleFormClick}>
                    <div id={`${fieldName}_${this.state.key}`} className='form-field-validation'>
                        <input ref={this.myNameInput} name={`${fieldName}_${this.state.key}`} type="text" defaultValue="" id={`${fieldName}_${this.state.key}`} onChange={this.handleChange}/>
                        <input ref={this.validFrom} name="valid_from" type="text" defaultValue={this.state.message}/>
                    </div>
                    <Form.Field id='fullname' className="input-name">
                        {this.state.fullnameValidation && (
                            <Label basic color='red' pointing='below'>
                                <FormattedMessage
                                    id={this.state.fullnameValidation}
                                    values={{field: <b>Name</b>}} />
                            </Label>
                        )}
                        <Input
                            name='fullname'
                            placeholder='Name'
                            icon='user'
                            iconPosition='left'
                            value={this.state.fullname}
                            onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field id='email'>
                        {this.state.emailValidation && (
                            <Label basic color='red' pointing='below'>
                                <FormattedMessage
                                    id={this.state.emailValidation}
                                    values={{field: <b>Email</b>}} />
                            </Label>
                        )}
                        <Input
                            icon='at'
                            iconPosition='left'
                            name='email'
                            placeholder='Email'
                            value={this.state.email}
                            onChange={this.handleChange}
                            action={{ icon: 'arrow right', color: 'blue', loading: this.state.isLoading }}/>
                    </Form.Field>
                </Form>
                <ThankyouModal
                    modalOpen={ this.state.is_modal_open }
                    handleCloseModal={ this.handleCloseModal }
                />
            </Container>
        )
    }
}
