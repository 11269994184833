import React, { Component } from 'react';
import { Container, Header, Grid } from 'semantic-ui-react';
import Service from "./Service";

export default class Services extends Component {
    render() {
        return (
            <Container className='services section' textAlign="center" id="services">
                <Header as='h2' className='header'>Nuestros Servicios</Header>
                <Grid stackable centered>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Service
                                title="Limpiezas Dentales"
                                description="Es la base de la salud bucal, hacerlo regularmente (cada 6 meses) previene tratamientos mas costosos, y complejos.  Básicamente existen 2 tipos de limpieza, una básica y una avanzada que se hace cuando por algún razón no has podido hacerte tus limpiezas en un tiempo prolongado y se ha acumulado mucho sarro en  tus dientes."
                                image={`${process.env.REACT_APP_CLOUDFRONT}/services/dental_cleaning.jpg`}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Service
                                title="Blanqueamientos"
                                description="A todos nos encanta tener los dientes más blancos, esta es la manera más económica y rápida de lograr aclarar tu sonrisa!!!"
                                image={`${process.env.REACT_APP_CLOUDFRONT}/services/whitening.jpg`}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Service
                                title="Ortodoncia"
                                description="La posición de tus dientes es de suma importancia, ya que esto ayudará a que puedas limpiarte adecuadamente, además de que lucirás una sonrisa maravillosa!!!"
                                image={`${process.env.REACT_APP_CLOUDFRONT}/services/orthodontics.jpg`}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Service
                                title="Implantología"
                                description="Cuando desafortunadamente has perdido un diente, es importante que ese espacio sea reemplazado con un diente protésico ya que la falta de cualquier pieza puede causar que los dientes antagonistas y/o adyacentes se muevan hacia posiciones indeseadas, causando mayores problemas."
                                image={`${process.env.REACT_APP_CLOUDFRONT}/services/implantology.jpg`}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Service
                                title="Endodoncia"
                                description="Este es un tratamiento con el que nuestro especialista intentará salvar tu diente.  Cuando ya tienes un dolor de muelas es probable que la lesión en tu diente haya progresado tanto que ya genera dolor, éste es el tratamiento que puedes llegar a requerir."
                                image={`${process.env.REACT_APP_CLOUDFRONT}/services/endodontics.jpg`}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Service
                                title="Maxilofacial"
                                description="Esta es un área basta de la odontología, en la que se hacen los procedimientos que tienen que ver con la extracción de las muelas del juicio, o terceros molares.  Pero también hay cirugías más complejas en las que se puede hacer la corrección de una mandíbula con exceso de longitud o con un crecimiento deficiente, etc."
                                image={`${process.env.REACT_APP_CLOUDFRONT}/services/maxillofacial.jpg`}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Service
                                title="Periodoncia"
                                description="Cuando la técnica de cepillado de tus dientes no es adecuada, o cuando hay lesiones en tus encías, incluso cuando hay mal olor en la boca (halitosis) es necesario que un especialista en esta parte de la boca te revise, él podrá apoyarte a resolver este tema, y te oriente para que este problema no vuelva a ocurrir."
                                image={`${process.env.REACT_APP_CLOUDFRONT}/services/periodontics.jpg`}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Service
                                title="Odontopediatría"
                                description="Para los pequeños de la casa, es importante adquirir buenos hábitos desde la infancia, y hacer las correcciones necesarias para evitar problemas cuando crezcan, nuestros especialistas identificarán e interceptaran los problemas que pudieran presentarse en un futuro."
                                image={`${process.env.REACT_APP_CLOUDFRONT}/services/odontpediatric.jpg`}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}
