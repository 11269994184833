import React, { Component } from 'react'
import PropTypes from "prop-types";
import { Menu, Segment, Responsive } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import SocialNetworks from './SocialNetworks'
import HeaderIcon from './HeaderIcon'

class Header extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };
    render() {
        return (
            <Segment basic className="header">
                <Responsive as={Menu} minWidth={Responsive.onlyTablet.maxWidth} inverted pointing secondary>
                    <HeaderIcon />
                    <SocialNetworks isSubMenu />
                </Responsive>
                <Responsive as={Menu} maxWidth={Responsive.onlyTablet.maxWidth} className="mobile-menu" inverted pointing secondary>
                    <HeaderIcon />
                </Responsive>
            </Segment>
        )
    }
}
export default withRouter(Header)
