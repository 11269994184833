import React from 'react';
import { Card, Image } from 'semantic-ui-react';

export default function Service(props){
    return (
        <Card centered>
          <Image src={props.image} wrapped ui={false} alt={props.title.charAt(0).toUpperCase() + props.title.slice(1)}/>
          <Card.Content>
            <h3 class="header">{props.title}</h3>
            <Card.Description>{props.description}</Card.Description>
          </Card.Content>
        </Card>
    )
}
