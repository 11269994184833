import React, { Component } from 'react';
import { Container, Header, Grid } from 'semantic-ui-react';
import TeamMember from './TeamMember';

export default class Team extends Component {
    render() {
        return (
            <Container className='team section' textAlign="center" id="team">
                <Header as='h2' className='header'>Nuestro Equipo</Header>
                <Grid stackable centered>
                    <Grid.Row columns={3}>
                        <Grid.Column textAlign="center">
                            <TeamMember
                                name="Dra. Lucía Zarate"
                                position="Dentista"
                                image={`${process.env.REACT_APP_CLOUDFRONT}/team/lucia_zarate.jpg`}/>
                        </Grid.Column>
                        <Grid.Column textAlign="center">
                            <TeamMember
                                name="Dr. José Roberto Quintero"
                                position="Dentista"
                                image={`${process.env.REACT_APP_CLOUDFRONT}/team/jose_quintero.jpg`}/>
                        </Grid.Column>
                        <Grid.Column textAlign="center">
                            <TeamMember
                                name="Dr. Iván Bravo"
                                position="Dentista"
                                image={`${process.env.REACT_APP_CLOUDFRONT}/team/ivan_bravo.jpg`}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}
