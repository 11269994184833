import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from "react-intl";
import { ToastContainer } from 'react-toastify';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './sections/NotFound';
import Privacy from './sections/Privacy';
import Terms from './sections/Terms';
import Home from './sections/Home';

import * as serviceWorker from './serviceWorker';

import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";

const flatten = require('flat');
const messages = {
    'en': flatten(messages_en),
    'es': flatten(messages_es)
};
const language = navigator.language.split(/[-_]/)[0];

const routing = (
  <Router>
    <IntlProvider locale={language} messages={messages[language]}>
      <ScrollToTop>
        <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/terms-and-conditions" component={Terms} />
            <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    </IntlProvider>
    <ToastContainer position="bottom-center" />
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
