import React from 'react'
import Main from '../layouts/Main';
import { Header, Container } from 'semantic-ui-react';

class Terms extends React.Component {
    render() {
        return (
            <Main>
                <Container className='privacy-container'>
                    <Header as='h1' inverted>Terms and Conditions for Dakota Dental</Header>
                    <p>Please read these Terms of Use carefully before using this website. By accessing or using this website, you agree to be bound by these Terms of Use. If you do not agree to be bound in this way, do not continue to access or use this website. We may modify these Terms of Use at any time. Any such modifications are effective and binding on you immediately from the time they are included on this website. You agree to review these Terms of Use regularly to ensure you become aware of any modifications to them. This website www.dakotadental.mx is operated by Dakota Dental Merchandising Ltd ("We", “Us”, “Our” or as the context requires).</p>
                    <Header as='h1' inverted>Accessing This Website</Header>
                    <p>Access to this website is permitted on a temporary basis, and We reserve the right to withdraw or amend the service We provide on this website without notice. We will not be liable if for any reason this website is unavailable at any time or for any period. From time to time, We may restrict access to some parts or all parts of this website to users who have registered to use certain services. Terms and conditions relating to use of these restricted services are set out below. We presently do not charge a fee for providing such services, although We reserve the right to do so in the future, provided that users will in such event be given the option not to receive the services from such time as a fee may be introduced. Access to restricted parts of this website is restricted to users of 14 years of age or over. Users under the age of 18 confirm that they have the consent of their parents or guardian to access this website and to these Terms of Use. If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any third party. We have the right to disable any user identification code or password, whether chosen by you or allocated by Us, at any time, if in our opinion you have failed to comply with any of the provisions of these Terms of Use, or any other terms and conditions that may govern your access and use of specific parts of this website. You are responsible for making all arrangements necessary for you to have access to this website. You are also responsible for ensuring that all persons who access this website through your internet connection are aware of these Terms of Use, and that they comply with them.</p>
                    <Header as='h1' inverted>Accetable Use</Header>
                    <p>You may use this website only for lawful purposes and in particular must not use it:</p>
                    <ul>
                        <li>in any way that breaches any applicable local, national or international law or regulation or that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</li>
                        <li>or the purpose of harming or attempting to harm minors in any way;</li>
                        <li>to send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards below;</li>
                        <li>to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
                    </ul>
                    <p>You also agree not to access without authority, interfere with, damage or disrupt: any part of this website; any equipment or network on which this website is stored; any software used in the provision of this website; or any equipment or network or software owned or used by any third party.</p>
                </Container>
            </Main>
        )
    }
}

export default Terms
