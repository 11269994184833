import React, { Component } from 'react';
import { Menu, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default class HeaderIcon extends Component{
    render(){
        return (
            <Menu.Item className="header-icon" as={Link} to='/'>
                <Image src={`${process.env.REACT_APP_CLOUDFRONT}/logo.png`} size='tiny' verticalAlign='middle' alt="Dakota Dental"/>
            </Menu.Item>
        )
    }
}
