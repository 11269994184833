import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ThankyouModal from './ThankyouModal';
import LaravelEncrypter from '../LaravelEncrypter';
import { Container, Header, Grid, Form, Button, Select, TextArea, Input } from 'semantic-ui-react';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import ReactGA from 'react-ga';
import ToastContainer from './ToastContainer';
import ReactPixel from 'react-facebook-pixel';

const options = [
  { key: 'bl', text: 'Blanqueamientos', value: 'Blanqueamientos' },
  { key: 'ld', text: 'Limpiezas Dentales', value: 'Limpiezas Dentales' },
  { key: 'or', text: 'Ortodoncia', value: 'Ortodoncia' },
  { key: 'im', text: 'Implantología', value: 'Implantología' },
  { key: 'en', text: 'Endodoncia', value: 'Endodoncia' },
  { key: 'mx', text: 'Maxilofacial', value: 'Maxilofacial' },
  { key: 'pe', text: 'Periodoncia', value: 'Periodoncia' },
  { key: 'op', text: 'Odontopediatría', value: 'Odontopediatría' },
]

export default class ContactFormSection extends Component {
    constructor(props) {
        super(props);
        //Initialize Encrypter
        let encrypter = new LaravelEncrypter();
        encrypter.generateKey(44);
        //Init the state
        this.state = {
            loading: false,
            key: encrypter.getKey(),
            emailValidation: false,
            fullnameValidation: false,
            is_form_clicked: false,
            is_modal_open: false,
            message: encrypter.encrypt(Math.round(new Date().getTime()/1000).toString()),
            fullname:'', email:'' , telephone:'' , comments:'', isLoading: false,
            date: '', time: '', speciality: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.myNameInput = React.createRef();
        this.validFrom = React.createRef();
    }
    handleChange = (event, {name, value}) => {
        if (this.state.hasOwnProperty(name)) {
          this.setState({ [name]: value });
        }
    }
    //this.setState({ [e.target.name]: e.target.value })
    handleSubmit = (event) => {
        //Change Loading State
        this.setState({ isLoading: true });
        let body = {
            email: this.state.email,
            fullname: this.state.fullname,
            telephone: this.state.telephone,
            comments: this.state.comments,
            date: Math.round(new Date(this.state.date + ' ' + this.state.time).getTime()/1000),
            speciality: this.state.speciality
        };
        //Attach Other Values
        body[this.validFrom.current.name]= this.validFrom.current.value;
        body[this.myNameInput.current.name]= this.myNameInput.current.value;
        //Send the Request
        axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/appointments`, body).then(
            (response) => {
                this.setState({
                    is_modal_open: true, isLoading: false,
                    fullname:'', email:'', telephone:'', comments:'',date:'',time:'',speciality:''
                });
                ReactGA.modalview('/newsletter/thank-you');
                ReactGA.event({ category: 'Contact', action: 'Subscribed'});
                ReactPixel.track('Contact', {action: 'Subscribed'});
            },
            (error) => {
                this.setState({ isLoading: false });
                if(error.response && error.response.status === 400) {
                    Object.keys(error.response.data).forEach(key => {
                        if(error.response.data[key].length > 0)
                            this.setState({[`${key}Validation`]: error.response.data[key][0]});
                    })
                } else {
                    toast.error(<ToastContainer icon='mail' message='An Error ocurred, please try again later.' />);
                }
                console.error(error.response);
            }
        );
        event.preventDefault();
    }
    handleFormClick = () => {
        if(!this.state.is_form_clicked){
            this.setState({ is_form_clicked: true });
            ReactGA.event({ category: 'Contact', action: 'Form Clicked'});
            ReactPixel.track('Contact', {action: 'Form Clicked'});
        }
    }
    handleCloseModal = () => this.setState({ is_modal_open: false });
    render() {
        const fieldName = 'my_name';
        return (
            <Container className='contact-form section' textAlign="center" id="contact-form">
                <Header as='h2' className='header'>Contacto</Header>
                <Grid stackable centered>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.5198293165727!2d-99.14769478240089!3d19.346631614480177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ffd2df45143f%3A0x5c91d25ddfb3805e!2sCalle%20Dakota%2045%2C%20Parque%20San%20Andr%C3%A9s%2C%20Coyoac%C3%A1n%2C%2004040%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1593351525540!5m2!1ses-419!2smx" width="100%" height="360" frameBorder="0" style={{border:0}} allowFullScreen title="Google Maps Indications"></iframe>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as='h4' className="form-header" textAlign="center">Agenda tu cita el día de hoy</Header>
                            <Form onSubmit={this.handleSubmit} onClick={this.handleFormClick}>
                                <div id={`${fieldName}_${this.state.key}`} className='form-field-validation'>
                                    <input ref={this.myNameInput} name={`${fieldName}_${this.state.key}`} type="text" defaultValue="" id={`${fieldName}_${this.state.key}`} onChange={this.handleChange}/>
                                    <input ref={this.validFrom} name="valid_from" type="text" defaultValue={this.state.message}/>
                                </div>
                                <Form.Field
                                    icon="user"
                                    iconPosition='left'
                                    control={Input}
                                    name="fullname"
                                    value={this.state.fullname}
                                    placeholder='Nombre Completo'
                                    onChange={this.handleChange}/>
                                <Form.Group widths='equal'>
                                    <Form.Field
                                        icon="at"
                                        iconPosition='left'
                                        control={Input}
                                        name="email"
                                        value={this.state.email}
                                        placeholder='Correo Electrónico'
                                        onChange={this.handleChange}/>
                                    <Form.Field
                                        icon="phone"
                                        iconPosition='left'
                                        control={Input}
                                        name="telephone"
                                        value={this.state.telephone}
                                        placeholder='Teléfono'
                                        onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <DateInput
                                        name="date"
                                        placeholder="Date"
                                        value={this.state.date}
                                        iconPosition="left"
                                        onChange={this.handleChange}/>
                                    <TimeInput
                                        name="time"
                                        placeholder="Time"
                                        value={this.state.time}
                                        iconPosition="left"
                                        onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Field
                                    name="speciality"
                                    control={Select}
                                    options={options}
                                    onChange={this.handleChange}
                                    value={this.state.speciality}
                                    placeholder='Tipo de Especialidad'/>
                                <Form.Field
                                    name="comments"
                                    id='form-textarea-control-opinion'
                                    control={TextArea}
                                    onChange={this.handleChange}
                                    value={this.state.comments}
                                    placeholder='Déjale un comentario al doctor...'/>
                                <Button type='submit' floated="right" loading={this.state.isLoading}>Enviar</Button>
                            </Form>
                            <ThankyouModal
                                modalOpen={ this.state.is_modal_open }
                                handleCloseModal={ this.handleCloseModal }
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}
