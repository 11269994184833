import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/captioned.css';
import '../sass/main-carrousel.scss';
import { Button, Header, Container, Segment, Icon } from 'semantic-ui-react';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function MainSlider() {
    return (
        <AutoplaySlider
            play={true}
            interval={5000}
            cancelOnInteraction={false}
            className="main-slider aws-btn">
            <div data-src={`${process.env.REACT_APP_CLOUDFRONT}/sliders/1.jpg`} data-alt="Dakota Dental" className="captioned">
                <Container>
                    <Segment basic compact >
                        <Header className="white">Ven y vive una experiencia única pensada para ti!</Header>
                        <Button size="huge" color='green' as="a" target="_blank" href={`//wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=Quiero%20más%20información%20de%20sus%20servicios`}>
                            <Icon name='whatsapp'/>Contactanos hoy mismo
                        </Button>
                    </Segment>
                </Container>
            </div>
            <div data-src={`${process.env.REACT_APP_CLOUDFRONT}/sliders/2.jpg`} data-alt="Dakota Dental" className="captioned">
                <Container>
                    <Segment basic compact >
                        <Header className="white">Trato Amistoso, profesional con equipo de alta tecnología</Header>
                        <Button size="huge" as="a" href="#team" primary>Conoce nuestro equipo</Button>
                    </Segment>
                </Container>
            </div>
            <div data-src={`${process.env.REACT_APP_CLOUDFRONT}/sliders/3.jpg`} data-alt="Dakota Dental" className="captioned">
                <Container>
                    <Segment basic compact >
                        <Header className="white">Tenemos personal calificado y certificado para que tengas la confianza absoluta a un precio razonable</Header>
                        <Button size="huge" as="a" href="#services" primary>Conoce nuestros servicios</Button>
                    </Segment>
                </Container>
            </div>
            <div data-src={`${process.env.REACT_APP_CLOUDFRONT}/sliders/4.jpg`} data-alt="Dakota Dental" className="captioned">
                <Container>
                    <Segment basic compact >
                        <Header className="white">Creando Hermosas Sonrisas para todos nuestros clientes de la mano de profesionales</Header>
                        <Button size="huge" as="a" href="#contact-form" primary>Visitanos hoy mismo</Button>
                    </Segment>
                </Container>
            </div>
        </AutoplaySlider>
    )
}
