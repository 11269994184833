import React from 'react';
import { Segment, Grid, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function BottomFooter(){
    return (
        <Segment basic className="bottom-footer">
            <Grid padded stackable columns={2}>
                <Grid.Row verticalAlign='top' className="content">
                    <Grid.Column width={8} className="copy-right">
                        <p>© 2020 Todos los Derechos Reservados | <Link to="/">Dakota Dental</Link></p>
                    </Grid.Column>
                    <Responsive as={Grid.Column} width={7} textAlign="right" minWidth={Responsive.onlyMobile.maxWidth}>
                        <p><Link to="/privacy-policy">Aviso de Privacidad</Link> | <Link to="/terms-and-conditions">Términos y Condiciones</Link></p>
                    </Responsive>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}
