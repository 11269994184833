import React, { Component } from 'react'
import { Grid, Header, Segment, Container } from 'semantic-ui-react'
import SocialNetworks from './SocialNetworks'
import ContactForm from './ContactForm';

export default class Footer extends Component {
  render() {
    return (
        <Segment placeholder basic className="footer">
            <Grid columns={3} stackable textAlign='center' padded>
                <Grid.Row verticalAlign='top'>
                    <Grid.Column width={6} className="abstract-about">
                        <Container textAlign='justified'>
                            <Header as='h3' inverted>About Us</Header>
                            <p>Somos una empresa creada para mejorar la calidad de vida de nuestros pacientes, a travez de un equipo articulado de laboratorio y clínica dentales, poniendo especial atención a los detalles, buscando colaborar con odontólogos de la más alta especialidad en su ramo, manteniendo costos asequibles para dar el mayor valor a su inversión.</p>
                        </Container>
                    </Grid.Column>
                    <Grid.Column width={4} className="contact-form">
                        <ContactForm />
                    </Grid.Column>
                    <Grid.Column width={5} className="social-media-container">
                        <Header as='h3'  inverted>Follow Us</Header>
                        <SocialNetworks />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
  }
}
