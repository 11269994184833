import React from 'react';
import { Segment, Image } from 'semantic-ui-react';

export default function MainSlider(props) {
    return (
        <Segment basic>
            <p className="comment">{props.comment}</p>
            <p className="date">{props.date}</p>
            <p className="name">{props.name}</p>
            <Image src={`${process.env.REACT_APP_CLOUDFRONT}/${props.image}`} circular centered size="tiny" alt={props.name}/>
        </Segment>
    )
}
