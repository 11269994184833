import React from 'react';
import Main from '../layouts/Main';
import Team from '../components/Team';
import Commitment from '../components/Commitment';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import ContactFormSection from '../components/ContactFormSection';
import MainSlider from '../components/MainSlider';

function App() {
  return (
    <Main section='home' shade='black'>
        <MainSlider />
        <Commitment/>
        <Services/>
        <Team/>
        <Testimonials/>
        <ContactFormSection/>
    </Main>
  );
}

export default App;
