import React, { Component } from 'react';
import Testimonial from './Testimonial';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import '../sass/testimonials.scss';
import { Container, Header, Grid, Segment, Embed } from 'semantic-ui-react';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default class Testimonials extends Component {
    render() {
        return (
            <Container className='testimonials section' textAlign="center" id="testimonials">
                <Header as='h2' className='header'>Testimoniales</Header>
                <Grid stackable centered>
                    <Grid.Row columns={2}>
                        <Grid.Column textAlign="center" className="testimonial-container">
                            <Segment className="testimonial">
                            <AutoplaySlider
                                play={false}
                                interval={5000}
                                cancelOnInteraction={false}
                                className="testimonials-slider aws-btn">
                                <div className="slide-container">
                                    <Testimonial
                                        name="Daniel Gutiérrez"
                                        date="12 Junio 2019"
                                        image="testimonials/1.jpg"
                                        comment="La experiencía desde el primer día fue increíble! El trato siempre es muy profesional y amable por todo el personal. A mi familia le encanta ir a consulta."/>
                                </div>
                                <div className="slide-container">
                                    <Testimonial
                                        name="Steve Caballero"
                                        date="12 Junio 2019"
                                        image="testimonials/2.jpg"
                                        comment="La experiencía desde el primer día fue increíble! El trato siempre es muy profesional y amable por todo el personal. A mi familia le encanta ir a consulta."/>
                                </div>
                            </AutoplaySlider>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column textAlign="center">
                            <Embed id="C456-J3C5jM" source='youtube' autoplay={true} hd={true} placeholder={`${process.env.REACT_APP_CLOUDFRONT}/video-placeholder.jpg?v1`} alt="Dakota Dental"/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}
