import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Icon, Modal, Image, Header } from 'semantic-ui-react';

class ThankyouModal extends Component {
    render() {
        const avatar = require('../images/avatar.jpg');
        return (
            <Modal
                basic
                size='small'
                dimmer='blurring'
                className='thankyou-modal'
                open={this.props.modalOpen}>
                <Modal.Content>
                    <Container textAlign='center' className='avatar-container'>
                        <Header as='h1' inverted>Gracias por subscribirte!</Header>
                        <Image src={avatar} size='small' circular inline alt="Dakota Dental"/>
                        <br/><br/>
                        <p>Te envíaremos pronto noticias sobre Dakota Dental y tu cita medica a tu email, además te avisaremos cuando tengamos promociones exclusivas en nuestro sitio web.</p>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green'
                        onClick={ this.props.handleCloseModal }>
                        <Icon name='thumbs up outline' /> Si
                    </Button>
                </Modal.Actions>
                <Modal.Description className='privacy-container'>
                    <Link to='/privacy-policy' target='_blank'>*Si tienes alguna preocupación acerca de como tratamos tus datos, puedes verificar nuestra politica de privacidad.</Link>
                </Modal.Description>
            </Modal>
        )
    }
}

export default ThankyouModal;
